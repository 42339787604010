.activeLink {
  background-color: rgb(238, 238, 238);
}

.listItem {
  padding: 20px;
  color: #525252;
  font-weight: 500;
}

.linkBaseClass {
  text-decoration: none;
  color: #525252;
}

.topContainer{
  display: flex;
  flex-direction: row;
}
