.widget{
    margin: 10px;
    padding: 15px;
    background-color: white;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    display: inline-block;
    height: auto;
}

.widgetTitle {
    font-size: 20px;
    margin-bottom: 17px;
}

.button {
    margin: 8px;
}

.emptyLine{
    height: 1em
}

.wrapper { 
    display:flex;
  
    align-items:flex-start;
    align-content:flex-start;
  }
