.listItem {
    cursor: pointer;
    transition: background 0.3s linear; /* vendorless fallback */
    -moz-transition: background 0.3s linear; /* Firefox 4 */
    -webkit-transition: background 0.3s linear; /* Safari and Chrome */
    -o-transition: background 0.3s linear; /* Opera */
    -ms-transition: background 0.3s linear; /* Explorer 10 */
  }

   .listItem:hover {
    border-radius: 5px;
    background: hsla(0,0%,100%,.15);
  }
  
  .listItemActive {
    cursor: pointer;
    border-radius: 5px;
    background: hsla(0,0%,100%,.3);

    transition: background 0.3s linear; /* vendorless fallback */
    -moz-transition: background 0.3s linear; /* Firefox 4 */
    -webkit-transition: background 0.3s linear; /* Safari and Chrome */
    -o-transition: background 0.3s linear; /* Opera */
    -ms-transition: background 0.3s linear; /* Explorer 10 */
  }